<template>
	<app tabbar create-button @create="navigateTo('/contract_create')">
		<tab-list-m :tab="tabList" @change="updateList"></tab-list-m>
		<scroll-list-m ref="list">
			<template v-slot="{data}">
				<scroll-item-m>
					<div class="flex-between-center">
						<div class="code">合同编号：{{data.code}}</div>
						<div class="state"><span :style="{'color': contractState[data.qs_state].color}">{{contractState[data.qs_state].name}}</span></div>
					</div>
					<div class="name">{{data.name}}</div>
					<div class="info">甲方单位：{{data.yzdw_name}}</div>
					<div class="info">乙方单位：{{data.zbqy_name}}</div>
					<div class="info">签约截止日：{{data.qy_last_date}}</div>
					<button-list-m slot="button">
						<c-button v-if="data.qs_state == 4" @click="navigateTo('/contract_edit', {id: data.id})">编辑</c-button>
						<c-button v-if="data.qs_state == 0" @click="navigateTo('/contract_detail', {id: data.id})">审批</c-button>
						<c-button v-else-if="data.qs_state == 1" @click="navigateTo('/contract_detail', {id: data.id})">签署</c-button>
						<c-button v-else color="gray" hollow @click="navigateTo('/contract_detail', {id: data.id})">详情</c-button>
					</button-list-m>
				</scroll-item-m>
			</template>
		</scroll-list-m>
	</app>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		data() {
			return {
				tabList: [{
					name: '待审批合同',
					param: {
						qs_states: [0, 1]
					}
				}, {
					name: '合同列表',
				}]
			}
		},
		
		computed: {
			...mapGetters(['contractState'])
		},
		
		methods: {
			updateList(data) {
				this.$refs.list.load({
					url: '/project/ht/list',
					data: data.param
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>