<template>
	<mobile v-if="mobile"></mobile>
	<pc v-else></pc>
</template>

<script>
	import pc from './contract_list_pc.vue'
	import mobile from './contract_list_m.vue'
	import {mapState} from 'vuex'
	
	export default {
		name: 'contract_list',
		
		components: {
			pc,
			mobile
		},
		
		computed: {
			...mapState(['mobile'])
		}
	};
</script>

<style lang="stylus" scoped>
</style>